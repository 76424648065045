<template>
  <v-btn
    icon
    color="btPrimary"
    dark
    @click="clickAppendIcon"
  >
    <v-icon :size="size">mdi-help-circle-outline</v-icon>
  </v-btn>
</template>
<script>
export default {
  components: {
  },
  props: {
    reference: String,
    label: String,
    refDoc: Boolean,
    refImg: Boolean,
    size: {
      default: "32"
    },
  },
  methods: {
    clickAppendIcon(e) {
      if (this.refDoc) {
        this.$store.commit('open_referenceDocument', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
      if (this.refImg) {
        this.$store.commit('open_referenceImage', {top: e.clientY, left: e.clientX, id: this.reference, label: this.label})
      }
    },
    _input(val) {
      this.$emit('input', val)
    },
  },
}
</script>
