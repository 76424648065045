<template>
  <div class="reference-sites-wrap">
    <!-- <v-row class="ma-0 pa-0">
      <v-col align="right" class="ma-0 pa-0">
        <input-sample class="input-sample" :refDoc="true" reference="ReferenceSites_referenceSites.txt" label="参考サイト"></input-sample>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col v-for="(site, i) in value.data" :key="i">
        <reference-site :value="site" @input="update($event, i)" :title="titles[i]" :clearable="!readonly" :readonly="readonly"></reference-site>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReferenceSite from '../molecules/ReferenceSite';
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    ReferenceSite,
    InputSample,
  },
  props: {
    value: {
      type:     Object,
      require:  false,
      default:  () => null,
    },
    readonly: Boolean,
  },
  data() {
    return {
      template: {
        data: [],

        inputStatusReferencesite: '',
      },
      itemTemplate: {
        siteName:       '',
        siteUrl:        '',
        siteImageUrl:   '',
        referencePoint: '',
        seq:            0,
      },
      titles: [ '参考1', '参考2', '参考3', ],
      amount: 3,
    }
  },

  created() {
    this.init()
  },

  methods: {
    // 初期化
    init() {
      if (this.value) {
        // 値が指定されている場合
        const site = {
          reference: this.value.data ?? this.template.data,

          inputStatusReferencesite: this.value.inputStatusReferencesite ?? this.template.inputStatusReferencesite,
        }
        this.$emit('input', site)

      } else {
        // 値が指定されていない場合
        const site = JSON.parse(JSON.stringify(this.template))
        this.$emit('input', site)
      }

      for (let i = 0; i < this.amount; i++) {
        if (this.value.data.length && this.value.data[i] && Object.keys(this.value.data[i]).length) {
          // 値が指定されている場合
          const site = this.value.data[i]
          let sites = JSON.parse(JSON.stringify(this.value))
          sites.data[i] = {
            siteName:       site.siteName       ?? this.itemTemplate.siteName,
            siteUrl:        site.siteUrl        ?? this.itemTemplate.siteUrl,
            siteImageUrl:   site.siteImageUrl   ?? this.itemTemplate.siteImageUrl,
            referencePoint: site.referencePoint ?? this.itemTemplate.referencePoint,
            seq: i + 1
          }
          this.$emit('input', sites)
        } else {
          // 値が指定されていない場合
          const site = JSON.parse(JSON.stringify(this.itemTemplate))
          let sites = this.value
          site.seq = i + 1
          sites.data.push(site)
          this.$emit('input', sites)
        }
      }
    },

    // データの更新
    update(value, index) {
      let sites = JSON.parse(JSON.stringify(this.value))
      sites.data[index] = value
      this.$emit('input', sites)
    }
  },
}
</script>
<style lang="scss" scoped>
.reference-sites-wrap {
  .input-sample{
    margin-top: -3px;
  }
}
</style>